<template>
  <div class="contain">
    <div id="kefu-contain">
      <drag :id="'drag-contain'">
        <img id="customerService" src="../assets/images/kefu-icon.png" class="kefu">
      </drag>
    </div>
    <customservice></customservice>
  </div>
</template>

<script>
import Drag from './drag'
export default {
  name: 'kefu',
  components: {
    Drag,
    customservice: {
        render(createElement) {
          return createElement("script", {
            attrs: {
              type: "text/javascript",
              id: "qd30090730864818c5406bb0dc9e9e67ce9f136c48a1",
              src: "https://wp.qiye.qq.com/qidian/3009073086/4818c5406bb0dc9e9e67ce9f136c48a1"
            },
          });
        },
    }
  }
}
</script>

<style lang="scss" scoped>
.contain{ 
    pointer-events: none;
    height:100vh;
    position: fixed;
    top: 300px;
    width: 100%;
    z-index: 999;
}
#kefu-contain{
    width: 100%;
    height: 100%;
    position: relative;
  #drag-contain{
    bottom: 420px;
    right: 0px;
    z-index: 99;
    pointer-events: auto;
  }
  img {
    width: 130px;
    height: 130px;
    display: block;
  }
}
</style>