<template>
  <div
    class="container"
    v-if="$route.meta && $route.meta.showTabbar"
  >
    <div class="current-van-tabbar__placeholder"></div>
    <van-tabbar
      value="$route.name"
      :fixed="true"
      :placeholder="false"
      :route="true"
      active-color="#006AFA"
      inactive-color="#999999"
      :border="false"
    >
      <!-- name是路由的name, to是跳转的地址 -->
      <van-tabbar-item
        name="index"
        to="/"
      >
        <template #icon="props">
          <img
            class="tabbar-icon"
            :src="props.active ? require('@/assets/images/tabbar/home1.png') : require('@/assets/images/tabbar/home2.png')"
          />
        </template>
        <div class="tabbar-text">首页</div>
      </van-tabbar-item>
      <van-tabbar-item
        name="custom_service"
        to="/chat"
      >
        <template #icon="props">
          <van-badge v-if="unread_count > 0" :content="unread_count" :max="99" class="badge">
            <img class="tabbar-icon" :src="props.active ? require('@/assets/images/tabbar/chat1.png') : require('@/assets/images/tabbar/chat2.png')"/>
          </van-badge>
          <img v-else class="tabbar-icon" :src="props.active ? require('@/assets/images/tabbar/chat1.png') : require('@/assets/images/tabbar/chat2.png')"/>
        </template>
        <div class="tabbar-text">消息</div>
      </van-tabbar-item>
      <van-tabbar-item
        name="my"
        to="/my"
      >
        <template #icon="props">
          <img
            class="tabbar-icon"
            :src="props.active ? require('@/assets/images/tabbar/my1.png') : require('@/assets/images/tabbar/my2.png')"
          />
        </template>
        <div class="tabbar-text">我的</div>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import {sign} from '@/api/im'
import {TIM} from '@/lib/tim/tim.js'
import { mapGetters } from 'vuex'
export default {
  components: {
  },
  data() {
    return {
      unread_count: 0,
      lastGetListTime: 0
    }
  },
  computed: {
    ...mapGetters({
      getTimReady: 'chat/getTimReady'
    })
  },
  beforeDestroy() {
    let that = this;
    this.$tim.off(TIM.EVENT.SDK_READY, that.noReadyFun);
    this.$tim.off(TIM.EVENT.SDK_READY, that.readyFun)
    this.$tim.off(TIM.EVENT.CONVERSATION_LIST_UPDATED,that.getConversationList)
  },
  mounted() {
    this.genTestUserSig()
  },
  methods: {
    genTestUserSig () {
      //后期由后台生成
      // todo 后端接口获取tim用户信息，去登录
      // let SDKAPPID = 1400534813;
      // let EXPIRETIME = 604800;
      // let SECRETKEY = 'c7a37b0ffc24df6f7fdaee374435a12a0f7b7e10153157b15e54152ab07a29f7';
      // let generator = new LibGenerateTestUserSig(SDKAPPID, SECRETKEY, EXPIRETIME);
      // let userID = 'user0';
      // this.$store.dispatch('chat/doChatId', userID);
      // let userSig = generator.genTestUserSig(userID);
      sign()
        .then(res => {
          if (res.code == 0) {
            if (res.data) {
              this.loadData(res.data.user_id, res.data.sig);
            }
          }
        })
    },
    loadData (userID, userSig) {
      let that = this;
      this.$tim.login({
        userID: userID,
        userSig: userSig
      }).then(res => {
        if (res.code == 0) {
          this.$store.commit('chat/setChatId', userID);
          this.$tim.on(TIM.EVENT.SDK_READY, that.readyFun)
          this.$tim.on(TIM.EVENT.CONVERSATION_LIST_UPDATED,that.getConversationList)
        }
        if (res.data.repeatLogin === true) {
        }
      }).catch(err => {})
    },
    getConversationList() {
      if (this.getTimReady) {
        let currTime = new Date().getTime();
        if (currTime - this.lastGetListTime >= 1000) {
          this.lastGetListTime = currTime
          this.$tim.getConversationList().then(res => {
            if (res.code == 0) {
              let list = res.data.conversationList;
              this.unread_count = 0;
              list.forEach(item => {
                this.unread_count += item.unreadCount
              });
            }
          })
        }
      }
    },
    readyFun(event){
      this.setTimeReady(true)
    },
    noReadyFun(event){
      console.log(event)
      this.setTimeReady(false)
    },
    setTimeReady(v) {
      this.$store.commit('chat/setTimReady', v);
    }
  }
};
</script>
<style lang="scss" scoped>
.container {
  :deep(.van-tabbar) {
    height: 98px;
  }
  .current-van-tabbar__placeholder {
    @include safe-area-inset-bottom-height(98px);
  }
  :deep(.van-tabbar-item__icon){
      margin-bottom: 0px;
  }
}
.tabbar-icon {
  width: 56px;
  height: 56px;
}
.tabbar-text {
  font-size: 22px;
  font-weight: 500;
}
.badge{
  width: 56px;
  height: 56px;
  :deep(.van-badge--fixed){
    top: 10px;
    right: 8px;
  }
}
</style>