import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;
import Vant, { Toast } from 'vant';
import moment from 'moment';
import { Lazyload } from 'vant';
import 'vant/lib/index.css';
import '@/assets/front-style/pingFang.css';
import '@/assets/stylus/common.scss';
import '@vant/touch-emulator';
import wx from 'jweixin-npm'
import { tim } from '@/lib/tim/tim.js'

Vue.use(Vant);

// 复制插件，文档地址：https://github.com/Inndy/vue-clipboard2
import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard);

// https://v1.github.surmon.me/vue-awesome-swiper/
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper)

//px转vw，不传px后缀只传数字
Vue.prototype.$px2vw = function (num) {
  return (num / 7.5).toFixed(6) + 'vw';
}

Vue.prototype.$px2vwMin = function (num) {
  var reduce = 0
  let ua = navigator.userAgent.toLowerCase();
  if(ua.match(/MicroMessenger/i)=="micromessenger") {
    wx.miniProgram.getEnv(function(res) {
      if (res.miniprogram) {
        reduce = 92
      }
    })
  }
  return ((num - reduce) / 7.5).toFixed(6) + 'vw';
}

//金额格式化小数点后2位
Vue.prototype.$moneyFormat = function (price) {
  return Math.round(price * 100)/100
}

//后退
Vue.prototype.$back = function () {
  // if (window.history.length == 1) {
  //   router.push('/');
  // } else {
    router.back();
  // }
}


/**
 * 日期格式化对象 文档地址https://momentjs.com/
 * {{$moment().format("YYYY-MM-DD HH:mm:ss")}}
 * {{$moment("2020-12-12 12:12:12").format("YYYY年MM月")}}
 */
Vue.prototype.$moment = moment;
Vue.prototype.$tim = tim

//复制
Vue.prototype.$copy = function (message) {
  this.$copyText(message).then(function (e) {
    Toast('复制成功');
  }, function (e) {
    Toast('复制失败');
    console.log(e)
  })
}

document.title = '霄鸟云集采中心';

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
