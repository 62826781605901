<template>
  <div id="app">
    <keep-alive :include="cache">
      <router-view />
    </keep-alive>
    <common-tab-bar v-if="user"></common-tab-bar>
    <ke-fu v-if="unShowKeFu.indexOf($route.name) == -1"></ke-fu>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import CommonTabBar from "@/components/tabbar.vue";
import KeFu from "@/components/kefu.vue"
export default {
  components: {
    CommonTabBar,
    KeFu
  },
  data() {
    return {
      routerFrom: null,
      unShowKeFu: ['auth']
    }
  },
  mounted() {
    this.$store.commit('routerData/setCache');
  },
  provide() {
    return {
      _app: this,
    };
  },
  methods: {
    //刷新,该方法对同路由间跳转无效
    reload(name = null) {
      if (name == null) name = this.$route.name;
      if (this.cache.indexOf(name) != -1) { //在缓存里面才刷新，不在缓存里面等会addCache把非缓存页面变成缓存页面
        this.$store.commit('routerData/removeCache', name);
        this.$nextTick(() => {
          this.$store.commit('routerData/addCache', name);
        })
      }

    },
    //刷新上一页，如果存在，该方法对同路由间跳转无效
    reloadPrev() {
      if (this.routerFrom != null) {
        let name = this.routerFrom.name;
        this.reload(name);
      }
    },
  },
  computed: {
    ...mapState({
      cache: state => state.routerData.cache,
      user: state => state.user.user
    })
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        this.routerFrom = from;
        if (to.meta?.cache && to.query._t >= from.query?._t) {
          // console.log('刷新了');
          this.reload();
        }
      }
    }
  }
}
</script>
<style lang="scss">
body {
  margin: 0;
  padding: 0;
  font-family: PingFang SC;
  background: #f6f6f6;
  p{
    margin: 0;
  }
}
</style>
